<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <path
                d="M2 21L23 12L2 3V10L17 12L2 14V21Z"
                :fill="color"
            />
        </template>
    </AppIcon>
</template>
